<template>
  <div class="personal">
    <div class="head">
      <div class="van-image van-image--round home-img">
        <img :src="userInfo.headImg" class="van-image__img" />
      </div>
      <div>{{ userInfo.nickname }}</div>
    </div>
    <div class="list">
      <van-cell-group inset>
        <van-cell title="我的预约" icon="orders-o" is-link to="orders" />
        <van-cell title="我的邀请" icon="like-o" is-link to="Income" />
        <van-cell title="分享二维码" icon="qr" is-link to="Share" />
        <van-cell title="积分兑换" icon="balance-o" is-link to="Withdraw" />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: { headImg: require("../assets/head.png"), nickname: "---" },
      Authorization: localStorage.getItem(window.lsn),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let $this = null;
      $this = this;
      // 获取个人信息
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          $this.userInfo = res.data;
          if (!$this.userInfo.userinfo) {
            window.sign(window.location.href);
          } else {
            $this.userInfo.headImg = $this.userInfo.userinfo.headimgurl;
            $this.userInfo.nickname = $this.userInfo.userinfo.nickname;
          }
        }
      });
    },
    onSubmit() {
      // 修改昵称
      let $this = null;
      $this = this;
      this.$axios.defaults.headers.common.Authorization =
        localStorage.getItem("C_UID");
      this.$axios
        .put(
          this.$store.state.domain + "/my_infor",
          this.$qs.stringify({
            nickname: this.userInfo.nickname,
          })
        )
        .then(function (response) {
          $this.userInfo = response.data;
          $this.$toast("更新成功");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goCropper() {
      location.replace("/cropper");
    },
    onBack() {
      history.back();
    },
  },
};
</script>
<style lang="scss">
.personal {
  color: #000;
}
.head {
  padding: 20px 0 30px;
  margin-bottom: 20px;
  background-image: linear-gradient(#24dea1, #1fd3b3);
  border-radius: 0 0 20px 20px;
}
.head b {
  display: block;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 2px;
}
.home-img {
  margin: 10px 0 0;
  text-align: center;
  width: 4rem;
  height: 4rem;
  background-color: #f2f2f2;
  border: 1px #cccccc solid;
}
.list {
  .van-cell {
    text-align: left;
  }
}
</style>
